// File: frontend/src/services/superAdmin/pitchService.js

import { apiService } from '../api';
import { API_ENDPOINTS } from '../../config/constants';

export const getBaseUrl = () => {
  if (process.env.REACT_APP_APP_ENV === 'prod') {
    return 'https://allegory.app';
  } else if (process.env.REACT_APP_APP_ENV === 'dev') {
    return 'https://dev.allegory.app';
  }
  return 'http://localhost:3001';
};

export const pitchService = {
  // Get access list for a specific deck
  getAccessList: async (deckId, enterpriseId = null) => {
    console.group('Getting Pitch Access List');
    console.log('Deck ID:', deckId, 'Enterprise ID:', enterpriseId);
    
    try {
      const params = { deckId };
      if (enterpriseId) {
        params.enterpriseId = enterpriseId;
      }
      
      const response = await apiService.get(API_ENDPOINTS.ENTERPRISE.PITCH.GET_ACCESS_LIST, {
        params
      });
      
      console.log('Access list retrieved successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error getting access list:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Verify if a user has access to a pitch deck
  verifyAccess: async (email, deckId) => {
    console.group('Verifying Pitch Access');
    console.log('Email:', email, 'Deck ID:', deckId);
    
    try {
      const response = await apiService.post(API_ENDPOINTS.ENTERPRISE.PITCH.VERIFY_ACCESS, {
        email,
        deckId
      });
      
      console.log('Access verification successful:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error verifying access:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Add access record (without granting access yet)
  addAccess: async (email, deckId, enterpriseId, additionalData = {}) => {
    console.group('Adding Pitch Access Record');
    console.log('Email:', email, 'Deck ID:', deckId, 'Enterprise ID:', enterpriseId, 'Additional Data:', additionalData);
    
    try {
      const payload = {
        email,
        deckId,
        enterpriseId,
        ...additionalData
      };
      
      const response = await apiService.post(API_ENDPOINTS.ENTERPRISE.PITCH.ADD_ACCESS, payload);
      
      console.log('Access record added successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error adding access record:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Grant access to a pitch deck
  grantAccess: async (email, deckId, durationDays, enterpriseId, additionalData = {}) => {
    console.group('Granting Pitch Access');
    console.log('Email:', email, 'Deck ID:', deckId, 'Duration:', durationDays, 'days', 'Enterprise ID:', enterpriseId, 'Additional Data:', additionalData);
    
    try {
      const payload = {
        email,
        deckId,
        enterpriseId,
        durationDays: parseInt(durationDays, 10),
        ...additionalData
      };
      
      const response = await apiService.post(API_ENDPOINTS.ENTERPRISE.PITCH.GRANT_ACCESS, payload);
      
      console.log('Access granted successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error granting access:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Revoke access to a pitch deck
  revokeAccess: async (accessId) => {
    console.group('Revoking Pitch Access');
    console.log('Access ID:', accessId);
    
    try {
      const response = await apiService.post(API_ENDPOINTS.ENTERPRISE.PITCH.REVOKE_ACCESS, {
        accessId
      });
      
      console.log('Access revoked successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error revoking access:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Update access count for a pitch deck
  updateAccessCount: async (accessId) => {
    console.group('Updating Pitch Access Count');
    console.log('Access ID:', accessId);
    
    try {
      // Using PUT instead of POST to match API Gateway configuration
      const response = await apiService.put(API_ENDPOINTS.ENTERPRISE.PITCH.UPDATE_ACCESS_COUNT, {
        accessId
      });
      
      console.log('Access count updated successfully:', response);
      console.groupEnd();
      return response;
    } catch (error) {
      console.error('Error updating access count:', error);
      console.groupEnd();
      throw error;
    }
  },

  // Helper functions
  formatDate: (dateString) => {
    if (!dateString) return 'Never';
    
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }).format(date);
  },

  isExpired: (expiryDate) => {
    return new Date(expiryDate) < new Date();
  },
  
  // Generate a pitch link
  generatePitchLink: (enterpriseId, pitchId) => {
    if (!enterpriseId || !pitchId) return '#';
    
    const enterpriseHash = enterpriseId.substring(0, 8);
    
    // Use the React environment variable
    const baseUrl = process.env.REACT_APP_APP_ENV === 'prod' 
      ? 'https://allegory.app' 
      : process.env.REACT_APP_APP_ENV === 'dev'
        ? 'https://dev.allegory.app'
        : 'http://localhost:3001';
    
    return `${baseUrl}/${enterpriseHash}-${pitchId}`;
  }
};

export default pitchService;