// File: frontend/src/pages/SuperAdmin/components/common/SuperAdminSidebar/index.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTachometerAlt, faKey, faBuilding, faUsers, faBox, 
  faFileContract, faCreditCard, faShieldAlt, faEnvelope, 
  faDatabase, faChartBar, faHistory, faCog, faQuestionCircle 
} from '@fortawesome/free-solid-svg-icons';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import styles from './SuperAdminSidebar.module.css';

const SuperAdminSidebar = ({ activeSection, onNavigate }) => {
  const { t } = useLanguage();
  
  const navigationItems = [
    { id: 'dashboard', label: t('dashboard'), icon: faTachometerAlt },
    { id: 'pitch-access', label: t('pitch_access'), icon: faKey },
    { id: 'enterprises', label: t('enterprise_management'), icon: faBuilding },
    { id: 'users', label: t('user_management'), icon: faUsers },
    { id: 'products', label: t('product_management'), icon: faBox },
    { id: 'agreements', label: t('agreement_management'), icon: faFileContract },
    { id: 'subscriptions', label: t('subscription_management'), icon: faCreditCard },
    { id: 'rbac', label: t('rbac_management'), icon: faShieldAlt },
    { id: 'communications', label: t('communication_management'), icon: faEnvelope },
    { id: 'datasets', label: t('dataset_management'), icon: faDatabase },
    { id: 'reports', label: t('report_management'), icon: faChartBar },
    { id: 'audit-logs', label: t('audit_logs'), icon: faHistory },
    { id: 'settings', label: t('system_settings'), icon: faCog },
  ];
  
  return (
    <aside className={styles.sidebar}>
      <nav className={styles.navigation}>
        <ul className={styles.navList}>
          {navigationItems.map((item) => (
            <li key={item.id} className={styles.navItem}>
              <button
                className={`${styles.navButton} ${activeSection === item.id ? styles.active : ''}`}
                onClick={() => onNavigate(item.id)}
              >
                <FontAwesomeIcon icon={item.icon} className={styles.navIcon} />
                <span className={styles.navLabel}>{item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </nav>
      
      <div className={styles.sidebarFooter}>
        <button className={styles.helpButton}>
          <FontAwesomeIcon icon={faQuestionCircle} />
          <span>{t('help_and_resources')}</span>
        </button>
      </div>
    </aside>
  );
};

export default SuperAdminSidebar;