// File: frontend/src/App.js

import React from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import LocalizedRoutes from './components/routing/LocalizedRoutes';
import { ProtectedDashboardRoute, AdminRoute, SuperAdminRoute } from './components/routing';
import AuthRoute from './components/routing/AuthRoute';
import { LanguageProvider } from './contexts/LanguageContext';
import { SEOProvider } from './contexts/SEOContext';
import { SignUpProvider } from './contexts/SignUpContext';
import { SignInProvider } from './contexts/SignInContext';
import { AuthProvider } from './contexts/AuthContextV2';
import { UserProvider } from './contexts/UserContext';
import AutoLogoutWrapper from './components/AutoLogout/AutoLogoutWrapper';
// import { PitchSecurityProvider } from './pages/InvestorPitch/contexts/PitchSecurityContext';

import './styles/main.css';

import { PrivacyProvider } from './contexts/PrivacyContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { FeatureFlagProvider } from './contexts/FeatureFlagContext';
import { UserPreferencesProvider } from './contexts/UserPreferencesContext';
import { CSRFProvider } from './contexts/CSRFContext';
import { RoleBasedAccessProvider } from './contexts/RoleBasedAccessContext';
import { QueryCacheProvider } from './contexts/QueryCacheContext';
import { AnalyticsProvider } from './contexts/AnalyticsContext';
import { MeasurementUnitProvider } from './contexts/MeasurementUnitContext';
import { HeaderProvider } from './contexts/HeaderContext';

import MainLayout from './components/layout/MainLayout/MainLayout';
import NotFound from './pages/NotFound/NotFound';
import Status from './pages/Status/Status';
import API from './pages/API/API';
import SignIn from './pages/Auth/SignIn';
import SignUp from './pages/Auth/SignUp';
import SignOut from './pages/Auth/SignOut';
import SignOutInactivity from './pages/Auth/SignOutInactivity';
import EmailVerification from './pages/Auth/EmailVerification';
import VerificationSuccess from './pages/Auth/VerificationSuccess';
import VerificationFailure from './pages/Auth/VerificationFailure';
import AccountCreation from './pages/Auth/AccountCreation';
import VerifyEmail from './pages/Auth/VerifyEmail';
import SignInSuccess from './pages/Auth/SignInSuccess';
import SignInFailure from './pages/Auth/SignInFailure';

import Dashboard from './pages/Dashboard/Dashboard';
import DrivingScore from './pages/DrivingScore/DrivingScore';
import AI1Container from './pages/AI1/AI1Container';
import ChatHistory from './pages/AI1/components/ChatHistory/ChatHistory';
import AutoInsight from './pages/AutoInsight';
import VehicleDetail from './pages/VehicleDetail';
import PropertyInsight from './pages/PropertyInsight';
import PropertyDetail from './pages/PropertyDetail';
import PropertyBulkUpload from './pages/PropertyBulkUpload';
import Cave from './pages/Cave/Cave';
import EmbeddedInsurance from './pages/EmbeddedInsurance';
import Help from './pages/Help/Help';
import MileMonitor from './pages/MileMonitor/MileMonitor';
import Plato from './pages/Plato/Plato';
import UMS from './pages/UMS';
import QMS from './pages/QMS';
import QuoteDetail from './pages/QMS/QuoteDetail';
import QuoteSearch from './pages/QMS/QuoteSearch';
import PMS from './pages/PMS/PMS';
import UPS from './pages/UPS/UPS';
import CMS from './pages/CMS/CMS';
import LMS from './pages/LMS/LMS';
import DMS from './pages/DMS/DMS';
import TMS from './pages/TMS/TMS';
import RMS from './pages/RMS/RMS';
import MMS from './pages/MMS/MMS';
import RouteSafe from './pages/RouteSafe/RouteSafe';
import Settings from './pages/Settings/Settings';
import Socrates from './pages/Socrates/Socrates';
import SourceCode from './pages/SourceCode/SourceCode';
import SuperAdmin from './pages/SuperAdmin';
import Trips from './pages/Trips/Trips';
import TripInsight from './pages/TripInsight';
import TripDetail from './pages/TripDetail';
import Account from './pages/Account/Account';

import InvestorPitch from './pages/InvestorPitch/InvestorPitch'
import InvestorSeed from './pages/InvestorPitch/InvestorSeed';
import InvestorSeriesA from './pages/InvestorPitch/InvestorSeriesA';
import CustomerInsurers from './pages/InvestorPitch/CustomerInsurers';
import CustomerBrokers from './pages/InvestorPitch/CustomerBrokers';
import AnimationGeneration from './pages/AnimationGeneration/AnimationGeneration';

import { useEffect } from 'react';

const consoleError = console.error.bind(console);

console.error = (error, ...args) => {
  if (
    typeof error === 'string' &&
    error.startsWith('ResizeObserver loop completed with undelivered notifications.')
  ) {
    return;
  }
  consoleError(error, ...args);
};

const handleExtensionError = () => {
  window.addEventListener('error', (event) => {
    if (event.message.includes('extension port')) {
      event.preventDefault();
    }
  });
};

const App = () => {
  useEffect(() => {
    handleExtensionError();
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <SEOProvider>
          <AuthProvider>
            <UserProvider>
              <LanguageProvider>
                <AutoLogoutWrapper>
                  <SignUpProvider>
                    <SignInProvider>
                      <PrivacyProvider>
                        <ThemeProvider>
                          <NotificationProvider>
                            <FeatureFlagProvider>
                              <UserPreferencesProvider>
                                <CSRFProvider>
                                  <RoleBasedAccessProvider>
                                    <QueryCacheProvider>
                                      <AnalyticsProvider>
                                        <MeasurementUnitProvider>
                                          <HeaderProvider>
                                            <Routes>
                                              {/* Use spread operator to include LocalizedRoutes */}
                                              {LocalizedRoutes()}

                                              {/* Auth Routes (only accessible when not authenticated) */}
                                              <Route path="/sign-up" element={<AuthRoute><SignUp /></AuthRoute>} />
                                              <Route path="/sign-in" element={<AuthRoute><SignIn /></AuthRoute>} />
                                              <Route path="/sign-in-success" element={<AuthRoute><SignInSuccess /></AuthRoute>} />
                                              <Route path="/sign-in-failure" element={<AuthRoute><SignInFailure /></AuthRoute>} />
                                              <Route path="/email-verification" element={<AuthRoute><EmailVerification /></AuthRoute>} />
                                              <Route path="/verification-success" element={<AuthRoute><VerificationSuccess /></AuthRoute>} />
                                              <Route path="/verification-failure" element={<AuthRoute><VerificationFailure /></AuthRoute>} />
                                              <Route path="/account-creation" element={<AuthRoute><AccountCreation /></AuthRoute>} />
                                              <Route path="/verify-email" element={<AuthRoute><VerifyEmail /></AuthRoute>} />

                                              {/* Sign-out routes */}
                                              <Route path="/sign-out" element={<SignOut />} />
                                              <Route path="/sign-out-inactivity" element={<SignOutInactivity />} />

                                              {/* <Route><Route index element={<Navigate to={`/${DEFAULT_LOCALE}`} replace />} /></Route> */}

                                              {/* Public & Non-localized routes */}
                                              <Route path="/status" element={<MainLayout><Status /></MainLayout>} />
                                              {/* <Route path="/api" element={<MainLayout><API /></MainLayout>} /> */}
                                              
                                              {/* Pitch Routes */}
                                              <Route path="/ac864c38-fa0f-4c88-bc1d-9d0fc90bf075-1989" element={<MainLayout><InvestorPitch /></MainLayout>} />
                                              {/* <Route path="/ae1b7223-investor-series-a" element={<MainLayout><InvestorSeriesA /></MainLayout>} /> */}
                                              
                                              <Route path="/investor/series-a/:id" element={<MainLayout><InvestorSeriesA /></MainLayout>} />
                                              <Route path="/investor/seed/:id" element={<MainLayout><InvestorSeed /></MainLayout>} />
                                              <Route path="/customer/insurers/:id" element={<MainLayout><CustomerInsurers /></MainLayout>} />
                                              <Route path="/customer/brokers/:id" element={<MainLayout><CustomerBrokers /></MainLayout>} />

                                              <Route path="/ccc3bdad-918d-463b-a36a-7803b454c4dc-1989" element={<MainLayout><AnimationGeneration /></MainLayout>} />

                                              {/* Protected Dashboard Routes */}
                                              <Route path="/dashboard" element={<ProtectedDashboardRoute><Dashboard /></ProtectedDashboardRoute>} />
                                              <Route path="/account" element={<ProtectedDashboardRoute><Account /></ProtectedDashboardRoute>} />
                                              <Route path="/settings" element={<ProtectedDashboardRoute><Settings /></ProtectedDashboardRoute>} />
                                              <Route path="/help" element={<ProtectedDashboardRoute><Help /></ProtectedDashboardRoute>} />
                                              
                                              <Route path="/ai1" element={<ProtectedDashboardRoute><AI1Container /></ProtectedDashboardRoute>} />
                                              <Route path="/ai1/chat" element={<ProtectedDashboardRoute><ChatHistory /></ProtectedDashboardRoute>} />
                                              <Route path="/ai1/chat/:chat_id" element={<ProtectedDashboardRoute><AI1Container /></ProtectedDashboardRoute>} />
                                              <Route path="/cave" element={<ProtectedDashboardRoute><Cave /></ProtectedDashboardRoute>} />
                                              
                                              <Route path="/driving-score" element={<ProtectedDashboardRoute><DrivingScore /></ProtectedDashboardRoute>} />
                                              <Route path="/trips" element={<ProtectedDashboardRoute><TripInsight /></ProtectedDashboardRoute>} />
                                              <Route path="/trips/trip/:tripId" element={<ProtectedDashboardRoute><TripDetail /></ProtectedDashboardRoute>} />
                                              <Route path="/mile-monitor" element={<ProtectedDashboardRoute><MileMonitor /></ProtectedDashboardRoute>} />
                                              <Route path="/auto-insight" element={<ProtectedDashboardRoute><AutoInsight /></ProtectedDashboardRoute>} />
                                              <Route path="/auto-insight/vehicle/:vehicleId" element={<ProtectedDashboardRoute><VehicleDetail /></ProtectedDashboardRoute>} />
                                              <Route path="/property-insight" element={<ProtectedDashboardRoute><PropertyInsight /></ProtectedDashboardRoute>} />
                                              <Route path="/property-insight/property/:propertyId" element={<ProtectedDashboardRoute><PropertyDetail /></ProtectedDashboardRoute>} />
                                              <Route path="/property-bulk-upload" element={<ProtectedDashboardRoute><PropertyBulkUpload /></ProtectedDashboardRoute>} />
                                              <Route path="/route-safe" element={<ProtectedDashboardRoute><RouteSafe /></ProtectedDashboardRoute>} />
                                              <Route path="/embedded-insurance" element={<ProtectedDashboardRoute><EmbeddedInsurance /></ProtectedDashboardRoute>} />
                                              
                                              {/* Protected & Admin-only Dashboard Routes */}
                                              <Route path="/ums/*" element={<AdminRoute><UMS /></AdminRoute>} />
                                              <Route path="/qms" element={<AdminRoute><QMS /></AdminRoute>} />
                                              <Route path="/qms/:quoteId" element={<AdminRoute><QuoteDetail /></AdminRoute>} />
                                              <Route path="/qms/search" element={<AdminRoute><QuoteSearch /></AdminRoute>} />
                                              <Route path="/pms" element={<AdminRoute><PMS /></AdminRoute>} />
                                              <Route path="/ups" element={<AdminRoute><UPS /></AdminRoute>} />
                                              <Route path="/cms" element={<AdminRoute><CMS /></AdminRoute>} />
                                              <Route path="/lms" element={<AdminRoute><LMS /></AdminRoute>} />
                                              <Route path="/dms" element={<AdminRoute><DMS /></AdminRoute>} />
                                              <Route path="/tms" element={<AdminRoute><TMS /></AdminRoute>} />
                                              <Route path="/rms" element={<AdminRoute><RMS /></AdminRoute>} />
                                              <Route path="/mms" element={<AdminRoute><MMS /></AdminRoute>} />
                                              <Route path="/trips/:trip_id" element={<AdminRoute><Trips /></AdminRoute>} />
                                              
                                              {/* Protected & Super Admin-only Dashboard Routes */}
                                              <Route path="/source-code" element={<SuperAdminRoute><SourceCode /></SuperAdminRoute>} />
                                              <Route path="/super-admin-panel" element={<SuperAdminRoute><SuperAdmin /></SuperAdminRoute>} />
                                              <Route path="/plato" element={<SuperAdminRoute><Plato /></SuperAdminRoute>} />
                                              <Route path="/socrates" element={<SuperAdminRoute><Socrates /></SuperAdminRoute>} />

                                              {/* 404 NotFound Route - Should be last */}
                                              <Route path="*" element={<MainLayout><NotFound /></MainLayout>} />
                                            </Routes>
                                          </HeaderProvider>
                                        </MeasurementUnitProvider>
                                      </AnalyticsProvider>
                                    </QueryCacheProvider>
                                  </RoleBasedAccessProvider>
                                </CSRFProvider>
                              </UserPreferencesProvider>
                            </FeatureFlagProvider>
                          </NotificationProvider>
                        </ThemeProvider>
                      </PrivacyProvider>
                    </SignInProvider>
                  </SignUpProvider>
                </AutoLogoutWrapper>
              </LanguageProvider>
            </UserProvider>
          </AuthProvider>
        </SEOProvider>
      </Router>
    </HelmetProvider>
  );
};

if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}

export default App;