// File: frontend/src/pages/InvestorPitch/PitchDeck.js

import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import useSlideNavigation from './hooks/useSlideNavigation';
import SlideNavigation from './components/navigation/SlideNavigation';
import SlideQuickNav from './components/navigation/SlideQuickNav';
import { useLanguage } from '../../contexts/LanguageContext';
import { apiService } from '../../services/api';
import { API_ENDPOINTS } from '../../config/constants';
import styles from './PitchDeck.module.css';

const PitchDeck = ({ slides, deckId: propDeckId, enterpriseHash, requireAuth = true }) => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthorized, setIsAuthorized] = useState(!requireAuth);
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [accessData, setAccessData] = useState(null);
  
  // Determine the correct deckId based on URL
  const determineDeckIdFromUrl = () => {
    const path = location.pathname;
    console.log('Determining deckId from URL path:', path);
    
    // For the new URL format
    if (path.includes('/investor/series-a/')) {
      console.log('URL indicates Series A');
      return 'investor-series-a';
    } else if (path.includes('/investor/seed/')) {
      console.log('URL indicates Seed');
      return 'investor-seed';
    } else if (path.includes('/customer/insurers/')) {
      console.log('URL indicates Customer Insurers');
      return 'customer-insurers';
    } else if (path.includes('/customer/brokers/')) {
      console.log('URL indicates Customer Brokers');
      return 'customer-brokers';
    }
    
    console.log('Using default deckId from props:', propDeckId);
    return propDeckId;
  };
  
  // Use the determined deckId
  const actualDeckId = determineDeckIdFromUrl();
  
  // Log the determination
  console.log('Prop deckId:', propDeckId);
  console.log('Actual deckId being used:', actualDeckId);
  console.log('Enterprise hash:', enterpriseHash);

  const getBaseUrl = () => {
    if (process.env.REACT_APP_APP_ENV === 'prod') {
      return 'https://allegory.app';
    } else if (process.env.REACT_APP_APP_ENV === 'dev') {
      return 'https://dev.allegory.app';
    }
    return 'http://localhost:3001';
  };

  const {
    currentSlide,
    direction,
    slideVariants,
    goToNextSlide,
    goToPreviousSlide,
    goToSlide,
    handleKeyboardNavigation
  } = useSlideNavigation(slides.length);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyboardNavigation);
    return () => window.removeEventListener('keydown', handleKeyboardNavigation);
  }, [handleKeyboardNavigation]);

  // Check if user has access from localStorage
  useEffect(() => {
    if (!requireAuth) return;
    
    console.log('Checking localStorage for access with key:', `pitch_access_${actualDeckId}`);
    
    const storedAccess = localStorage.getItem(`pitch_access_${actualDeckId}`);
    if (storedAccess) {
      try {
        const accessData = JSON.parse(storedAccess);
        const now = new Date();
        const expiryDate = new Date(accessData.expires_at);
        
        console.log('Found stored access:', accessData);
        console.log('Expiry date:', expiryDate);
        console.log('Current time:', now);
        
        // Verify that the stored access matches the current enterprise hash
        const storedEnterpriseHash = accessData.enterprise_hash || 
          (accessData.enterprise_id ? accessData.enterprise_id.substring(0, 8) : null);
        
        console.log('Stored enterprise hash:', storedEnterpriseHash);
        console.log('Current enterprise hash:', enterpriseHash);
        
        if (now < expiryDate && (!enterpriseHash || storedEnterpriseHash === enterpriseHash)) {
          console.log('Access is valid, authorizing...');
          setIsAuthorized(true);
          setAccessData(accessData);
          
          // Update access count in the background
          updateAccessCount(accessData.access_id).catch(err => {
            console.error('Error updating access count:', err);
          });
        } else {
          console.log('Access is expired or enterprise hash does not match');
        }
      } catch (err) {
        console.error('Error parsing stored access:', err);
      }
    } else {
      console.log('No stored access found');
    }
  }, [actualDeckId, enterpriseHash, requireAuth]);

  const updateAccessCount = async (accessId) => {
    try {
      console.log('Updating access count for accessId:', accessId);
      await apiService.put(API_ENDPOINTS.ENTERPRISE.PITCH.UPDATE_ACCESS_COUNT, {
        accessId
      });
      console.log('Access count updated successfully');
    } catch (error) {
      console.error('Failed to update access count:', error);
      // Non-critical error, so we don't need to show it to the user
    }
  };

  const handleVerifyAccess = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
    
    console.log('Verifying access with email:', email);
    console.log('Using deckId for verification:', actualDeckId);
    
    try {
      const response = await apiService.post(API_ENDPOINTS.ENTERPRISE.PITCH.VERIFY_ACCESS, {
        email,
        deckId: actualDeckId // Use the determined deck ID, not the prop
      });
      
      console.log('API response:', response);
      
      if (response.success) {
        // Extract enterprise hash from response
        const accessEnterpriseHash = response.access.enterprise_hash || 
          (response.access.enterprise_id ? response.access.enterprise_id.substring(0, 8) : null);
        
        console.log('Access enterprise hash:', accessEnterpriseHash);
        
        // If we don't have an enterprise hash from the URL, don't do the comparison
        if (!enterpriseHash || accessEnterpriseHash === enterpriseHash) {
          console.log('Access granted, authorizing...');
          setIsAuthorized(true);
          setAccessData(response.access);
          
          // Store access in localStorage with expiry
          localStorage.setItem(`pitch_access_${actualDeckId}`, JSON.stringify(response.access));
          
          // Update access count
          updateAccessCount(response.access.access_id);
        } else {
          console.log('Enterprise hash mismatch, access denied');
          setError('Access denied. The provided email does not have access to this specific pitch deck.');
        }
      } else {
        console.log('API reported unsuccessful access:', response.message);
        setError(response.message || 'Access denied. Please contact the administrator.');
      }
    } catch (err) {
      console.error('Error verifying access:', err);
      if (err.status === 403) {
        setError('Access denied. Please contact the administrator.');
      } else {
        setError('An error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isAuthorized) {
    return (
      <div className={styles.authContainer}>
        <div className={styles.authCard}>
          <h2 className={styles.authTitle}>{t('investor_pitch.auth.title')}</h2>
          <p className={styles.authDescription}>{t('investor_pitch.auth.description')}</p>
          
          <form onSubmit={handleVerifyAccess} className={styles.authForm}>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>
                {t('investor_pitch.auth.email_label')}
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={styles.input}
                placeholder={t('investor_pitch.auth.email_placeholder')}
                required
              />
            </div>
            
            {error && <div className={styles.error}>{error}</div>}
            
            <button 
              type="submit" 
              className={styles.submitButton}
              disabled={isLoading}
            >
              {isLoading ? t('investor_pitch.auth.loading') : t('investor_pitch.auth.submit')}
            </button>

            <div style={{ marginTop: '10px', fontSize: '12px', color: '#666' }}>
              Using deck ID: {actualDeckId}
            </div>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.slideQuickNav}>
            <SlideQuickNav
              currentSlide={currentSlide}
              totalSlides={slides.length}
              slides={slides}
              onSlideSelect={goToSlide}
            />
          </div>
        </div>
      </header>

      <main className={styles.main}>
        <AnimatePresence mode="wait" custom={direction}>
          <motion.div
            key={currentSlide}
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            className={styles.slideContainer}
          >
            {slides[currentSlide].content}
          </motion.div>
        </AnimatePresence>
      </main>

      <SlideNavigation
        currentSlide={currentSlide}
        totalSlides={slides.length}
        onNext={goToNextSlide}
        onPrevious={goToPreviousSlide}
      />

      <footer className={styles.footer}>
        <div className={styles.footerInfo}>
          <div className={styles.confidentialInfo}>
            <span className={styles.confidential}>
              {t('investor_pitch.master.common.confidential')}
            </span>
            <span className={styles.separator}>•</span>
            <span>
              {t('investor_pitch.master.common.prepared_for')} {accessData?.enterprise_name || process.env.REACT_APP_ENTERPRISE_NAME}
            </span>
            <span className={styles.separator}>•</span>
            <span className={styles.enterprise_id}>
              ID: {accessData?.enterprise_id || process.env.REACT_APP_ENTERPRISE_ID}
            </span>
          </div>
          <div className={styles.copyright}>
            © {new Date().getFullYear()} {t('investor_pitch.master.common.company')}
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PitchDeck;