// File: frontend/src/pages/InvestorPitch/components/slides/HistoricalInventionSlide/HistoricalInventionSlide.js

import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './HistoricalInventionSlide.module.css';

const HistoricalInventionSlide = () => {
  const { t } = useLanguage();
  const canvasRef = useRef(null);
  const animationRef = useRef(null);

  // AI-1 animation effect
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    const particles = [];
    const particleCount = 100;
    
    // Set canvas dimensions
    const resizeCanvas = () => {
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
    };
    
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    
    // Create particles
    for (let i = 0; i < particleCount; i++) {
      particles.push({
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        radius: Math.random() * 2 + 1,
        color: `rgba(0, 149, 255, ${Math.random() * 0.5 + 0.1})`,
        speedX: Math.random() * 1 - 0.5,
        speedY: Math.random() * 1 - 0.5
      });
    }
    
    // Animation function
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Draw particles
      particles.forEach(particle => {
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fillStyle = particle.color;
        ctx.fill();
        
        // Update position
        particle.x += particle.speedX;
        particle.y += particle.speedY;
        
        // Boundary check
        if (particle.x < 0 || particle.x > canvas.width) particle.speedX *= -1;
        if (particle.y < 0 || particle.y > canvas.height) particle.speedY *= -1;
      });
      
      // Draw connections between particles
      particles.forEach((particle, i) => {
        particles.slice(i + 1).forEach(otherParticle => {
          const dx = particle.x - otherParticle.x;
          const dy = particle.y - otherParticle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < 100) {
            ctx.beginPath();
            ctx.strokeStyle = `rgba(0, 149, 255, ${0.2 * (1 - distance / 100)})`;
            ctx.lineWidth = 0.5;
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.stroke();
          }
        });
      });
      
      animationRef.current = requestAnimationFrame(animate);
    };
    
    animate();
    
    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationRef.current);
    };
  }, []);

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.historical_invention.title', 'The Biggest Invention Since the 17th Century')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.historical_invention.subtitle', 'Revolutionizing insurance like Edward Lloyd\'s coffee house did in 1688')}
        </p>
      </motion.div>

      <div className={styles.content}>
        <motion.div 
          className={styles.timelineContainer}
          variants={ANIMATION_VARIANTS.fadeIn}
        >
          <div className={styles.timelineItem}>
            <div className={styles.timelineYear}>1688</div>
            <div className={styles.timelineContent}>
              <h3>Edward Lloyd's Coffee House</h3>
              <p>The birthplace of modern insurance, where merchants and ship owners gathered to share risk.</p>
            </div>
          </div>
          
          <div className={styles.timelineDivider}>
            <div className={styles.timelineLine}></div>
            <div className={styles.timelineDot}></div>
            <div className={styles.timelineLine}></div>
          </div>
          
          <div className={styles.timelineItem}>
            <div className={styles.timelineYear}>2025</div>
            <div className={styles.timelineContent}>
              <h3>Allegory AI-1</h3>
              <p>The world's first autonomous underwriting intelligence, capable of assessing and pricing risk for all physical and non-physical assets.</p>
            </div>
          </div>
        </motion.div>

        <motion.div 
          className={styles.aiVisualization}
          variants={ANIMATION_VARIANTS.scaleIn}
        >
          <div className={styles.canvasContainer}>
            <canvas ref={canvasRef} className={styles.animationCanvas}></canvas>
            <div className={styles.aiLabel}>AI-1</div>
          </div>
          
          <motion.div 
            className={styles.inventorNote}
            variants={ANIMATION_VARIANTS.fadeIn}
            transition={{ delay: 0.5 }}
          >
            <div className={styles.inventorHeader}>
              <div className={styles.inventorAvatar}>
                <img src="/images/onurgungor2-v1.jpg" alt="Onur Gungor" />
              </div>
              <div className={styles.inventorInfo}>
                <h3>Onur Gungor</h3>
                <p>Inventor, Founder & CEO</p>
              </div>
            </div>
            <blockquote>
              "I've created a machine that can autonomously underwrite the world's physical and non-physical assets, transforming insurance the way Lloyd's transformed maritime risk 337 years ago."
            </blockquote>
          </motion.div>
        </motion.div>

        <motion.div 
          className={styles.capabilitiesContainer}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          <motion.div 
            className={styles.capabilityCard}
            variants={ANIMATION_VARIANTS.stagger.item}
          >
            <div className={styles.capabilityIcon}>🌐</div>
            <h3>Universal Risk Assessment</h3>
            <p>Evaluates any insurable asset across all categories</p>
          </motion.div>
          
          <motion.div 
            className={styles.capabilityCard}
            variants={ANIMATION_VARIANTS.stagger.item}
          >
            <div className={styles.capabilityIcon}>⚙️</div>
            <h3>Autonomous Operation</h3>
            <p>Functions 24/7/365 without human intervention</p>
          </motion.div>
          
          <motion.div 
            className={styles.capabilityCard}
            variants={ANIMATION_VARIANTS.stagger.item}
          >
            <div className={styles.capabilityIcon}>📊</div>
            <h3>Dynamic Pricing</h3>
            <p>Continuously optimizes premiums based on real-time data</p>
          </motion.div>
          
          <motion.div 
            className={styles.capabilityCard}
            variants={ANIMATION_VARIANTS.stagger.item}
          >
            <div className={styles.capabilityIcon}>🔄</div>
            <h3>Self-Improving</h3>
            <p>Learns from every transaction to enhance accuracy</p>
          </motion.div>
        </motion.div>
      </div>
    </motion.div>
  );
};


export default HistoricalInventionSlide;