// File: frontend/src/contexts/AnalyticsContext.js

import React, { createContext, useContext } from 'react';
// import * as analytics from '../services/analytics';

const AnalyticsContext = createContext();

export const AnalyticsProvider = ({ children }) => {
  const trackEvent = (eventName, eventData) => {
    // analytics.trackEvent(eventName, eventData);
  };

  return (
    <AnalyticsContext.Provider value={{ trackEvent }}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error('useAnalytics must be used within an AnalyticsProvider');
  }
  return context;
};

// // File: frontend/src/contexts/AnalyticsContext.js

// import React, { createContext, useContext, useEffect } from 'react';
// import AnalyticsService from '../services/AnalyticsService';
// import { useGDPRConsent } from './GDPRConsentContext';

// const AnalyticsContext = createContext();

// export const useAnalytics = () => {
//   return useContext(AnalyticsContext);
// };

// export const AnalyticsProvider = ({ children }) => {
//   const { hasConsent } = useGDPRConsent();

//   // Initialize analytics service when consent is granted
//   useEffect(() => {
//     if (hasConsent) {
//       AnalyticsService.initialize();
//     }
//   }, [hasConsent]);

//   const trackEvent = (eventName, data = {}) => {
//     if (hasConsent) {
//       AnalyticsService.sendAnalyticsData(eventName, data);
//     }
//   };

//   return (
//     <AnalyticsContext.Provider
//       value={{
//         trackEvent,
//         hasAnalyticsConsent: hasConsent
//       }}
//     >
//       {children}
//     </AnalyticsContext.Provider>
//   );
// };

// export default AnalyticsProvider;