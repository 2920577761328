// File: src/config/i18n.config.js

// category_type_id=44 --> This shares the promary language in db
export const SUPPORTED_LOCALES = {
  'de-de': { 
    name: 'Deutsch (Deutschland)', 
    flag: '🇩🇪',
    defaultForLang: true,
    languageCode: 'de',
    countryCode: 'de',
    categoryValueId: 3247,
    currency: 'EUR',
    currencySymbol: '€'
  },
  'en-ca': { 
    name: 'English (Canada)', 
    flag: '🇨🇦',
    defaultForLang: true,
    isDefault: true,
    languageCode: 'en',
    countryCode: 'ca',
    categoryValueId: 3245,
    currency: 'CAD',
    currencySymbol: '$'
  },
  'en-gb': { 
    name: 'English (United Kingdom)', 
    flag: '🇬🇧',
    defaultForLang: false,
    languageCode: 'en',
    countryCode: 'gb',
    categoryValueId: 3244,
    currency: 'GBP',
    currencySymbol: '£'
  },
  'en-us': { 
    name: 'English (USA)', 
    flag: '🇺🇸',
    defaultForLang: false,
    languageCode: 'en',
    countryCode: 'us',
    categoryValueId: 3243,
    currency: 'USD',
    currencySymbol: '$'
  },
  'es-es': { 
    name: 'Español (España)', 
    flag: '🇪🇸',
    defaultForLang: true,
    languageCode: 'es',
    countryCode: 'es',
    categoryValueId: 3231,
    currency: 'EUR',
    currencySymbol: '€'
  },
  'es-mx': { 
    name: 'Español (México)', 
    flag: '🇲🇽',
    defaultForLang: false,
    languageCode: 'es',
    countryCode: 'mx',
    categoryValueId: 3232,
    currency: 'MXN',
    currencySymbol: '$'
  },
  'fr-ca': { 
    name: 'Français (Canada)', 
    flag: '🇨🇦',
    defaultForLang: false,
    languageCode: 'fr',
    countryCode: 'ca',
    categoryValueId: 3235,
    currency: 'CAD',
    currencySymbol: '$'
  },
  'fr-fr': { 
    name: 'Français (France)', 
    flag: '🇫🇷',
    defaultForLang: true,
    languageCode: 'fr',
    countryCode: 'fr',
    categoryValueId: 3234,
    currency: 'EUR',
    currencySymbol: '€'
  },
  'nl-nl': { 
    name: 'Nederlands (Nederland)', 
    flag: '🇳🇱',
    defaultForLang: true,
    languageCode: 'nl',
    countryCode: 'nl',
    categoryValueId: 3249,
    currency: 'EUR',
    currencySymbol: '€'
  },
  'tr-tr': { 
    name: 'Türkçe (Türkiye)', 
    flag: '🇹🇷',
    defaultForLang: true,
    languageCode: 'tr',
    countryCode: 'tr',
    categoryValueId: 3279,
    currency: 'TRY',
    currencySymbol: '₺'
  }
};

export const DEFAULT_LOCALE = 'en-ca';

// Routes that should not have locale prefixes
export const NON_LOCALIZED_PATHS = [
  '/dashboard',
  '/account',
  '/ai1',
  '/ai1/chat',
  '/ai1/chat/:chat_id',
  '/auto-insight',
  '/auto-insight/vehicle/:vehicleId',
  '/property-insight',
  '/property-insight/property/:propertyId',
  '/cave',
  '/driving-score',
  '/embedded-insurance',
  '/help',
  '/mile-monitor',
  '/plato',
  '/ums',
  '/ums/portfolio',
  '/ums/:userId',
  '/qms',
  '/pms',
  '/ups',
  '/cms',
  '/lms',
  '/dms',
  '/tms',
  '/rms',
  '/mms',
  '/route-safe',
  '/settings',
  '/socrates',
  '/source-code',
  '/super-admin-panel',
  '/trips',
  '/trips/reports',
  '/trips/trip/:tripId',
  '/sign-in',
  '/sign-up',
  '/email-verification',
  '/verify-email',
  '/verification-success',
  '/verification-failure',
  '/account-creation',
  '/sign-out',
  '/sign-out-inactivity',
  '/api',
  '/docs',
  '/status',
  '/help',
  '/ac864c38-fa0f-4c88-bc1d-9d0fc90bf075-1989',
  '/ccc3bdad-918d-463b-a36a-7803b454c4dc-1989',
  // Add specific patterns for investor routes
  '/investor/series-a/:id',
  '/investor/seed/:id',
  '/customer/insurers/:id',
  '/customer/brokers/:id'
];

// SEO-related route configurations
export const ROUTE_CONFIGS = {
  home: {
    path: '/',
    priority: 1,
    changefreq: 'daily'
  },
  about: {
    path: '/about',
    priority: 0.7,
    changefreq: 'monthly'
  },
  aiAgents: {
    path: '/ai-agents',
    priority: 0.9,
    changefreq: 'weekly'
  },
  contact: {
    path: '/contact',
    priority: 0.7,
    changefreq: 'monthly'
  },
  privacy: {
    path: '/privacy',
    priority: 0.5,
    changefreq: 'monthly'
  },
  terms: {
    path: '/terms',
    priority: 0.5,
    changefreq: 'monthly'
  }
};

// Helper functions
export const isLocalizablePath = (path) => {
  // First, handle specific patterns for investor routes
  if (/^\/investor\/(series-a|seed)\/[a-z0-9-]+$/.test(path) || 
      /^\/customer\/(insurers|brokers)\/[a-z0-9-]+$/.test(path)) {
    return false;
  }
  
  // Then check the rest of the non-localized paths
  return !NON_LOCALIZED_PATHS.some(nonLocalizedPath => {
    // Convert route parameter patterns to regex
    const regexPattern = nonLocalizedPath
      .replace(/:\w+/g, '[^/]+') // Replace :param with any non-slash characters
      .replace(/\//g, '\\/');    // Escape forward slashes
    
    const regex = new RegExp(`^${regexPattern}($|\\/)`, 'i');
    return regex.test(path);
  });
};

export const getLocaleFromPath = (path) => {
  const pathSegments = path.split('/');
  const possibleLocale = pathSegments[1];
  return SUPPORTED_LOCALES[possibleLocale] ? possibleLocale : null;
};

export const getDefaultLocaleForLanguage = (languageCode) => {
  return Object.entries(SUPPORTED_LOCALES).find(
    ([locale, config]) => config.languageCode === languageCode && config.defaultForLang
  )?.[0] || DEFAULT_LOCALE;
};

export const stripLocaleFromPath = (path) => {
  const pathSegments = path.split('/');
  const possibleLocale = pathSegments[1];
  
  if (SUPPORTED_LOCALES[possibleLocale]) {
    return '/' + pathSegments.slice(2).join('/');
  }
  return path;
};

export const addLocaleToPath = (path, locale) => {
  const strippedPath = stripLocaleFromPath(path);
  return `/${locale}${strippedPath}`.replace('//', '/');
};

export const getAlternateLinks = (currentPath) => {
  const strippedPath = stripLocaleFromPath(currentPath);
  
  return Object.keys(SUPPORTED_LOCALES).map(locale => ({
    hrefLang: locale,
    href: addLocaleToPath(strippedPath, locale)
  }));
};