// File: frontend/src/pages/InvestorPitch/InvestorSeed.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useParams } from 'react-router-dom';
import PitchDeck from './PitchDeck';
import { getInvestorSeedSlides } from './configs/InvestorSeedConfig';

const InvestorSeed = () => {
  const { t } = useLanguage();
  const slides = getInvestorSeedSlides(t);
  const { id } = useParams();  // This will now get the id from the URL path

  const enterpriseHash = id;
  console.log('Extracted enterprise hash:', enterpriseHash);

  return <PitchDeck 
    slides={slides} 
    deckId="investor-seed"  // Explicitly set the deckId
    enterpriseHash={enterpriseHash} 
    requireAuth={true} 
  />;
};

export default InvestorSeed;