// Updated TractionSlide.js with Mapbox integration

import React, { useRef, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import { FiTrendingUp, FiShield, FiUsers, FiDollarSign, FiBarChart2, FiLock, FiGlobe, FiMessageSquare } from 'react-icons/fi';
import styles from './TractionSlide.module.css';
import Chart from 'chart.js/auto';
import MapComponent from '../../../../../components/Dashboard/MapComponent';
import mapData from '../../../../../data/mapbox_data_for_dashboard.json';

const TractionSlide = () => {
  const { t } = useLanguage();
  const trafficChartRef = useRef(null);
  const trafficChartInstance = useRef(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  
  // Chart data for website traffic
  // Updated chart data section in TractionSlide.js

useEffect(() => {
    if (trafficChartRef.current) {
      // Destroy existing chart if it exists
      if (trafficChartInstance.current) {
        trafficChartInstance.current.destroy();
      }
      
      const ctx = trafficChartRef.current.getContext('2d');
      
      // Real data from AWS WAF logs
      const dates = [
        '2025-01-30', '2025-01-31', '2025-02-01', '2025-02-02', '2025-02-03', '2025-02-04', '2025-02-05', '2025-02-06', 
        '2025-02-07', '2025-02-08', '2025-02-09', '2025-02-10', '2025-02-11', '2025-02-12', '2025-02-13', '2025-02-14', 
        '2025-02-15', '2025-02-16', '2025-02-17', '2025-02-18', '2025-02-19', '2025-02-20', '2025-02-21', '2025-02-22', 
        '2025-02-23', '2025-02-24', '2025-02-25', '2025-02-26', '2025-02-27', '2025-02-28', '2025-03-01', '2025-03-02', 
        '2025-03-03', '2025-03-04', '2025-03-05', '2025-03-06', '2025-03-07', '2025-03-08', '2025-03-09', '2025-03-10', 
        '2025-03-11', '2025-03-12', '2025-03-13', '2025-03-14', '2025-03-15', '2025-03-16', '2025-03-17', '2025-03-18', 
        '2025-03-19', '2025-03-20', '2025-03-21', '2025-03-22', '2025-03-23', '2025-03-24', '2025-03-25', '2025-03-26', 
        '2025-03-27', '2025-03-28', '2025-03-29', '2025-03-30', '2025-03-31', '2025-04-01'
      ];
      
      // Format dates for display
      const labels = dates.map(dateStr => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
      });
      
      // Real traffic data (allowed requests)
      const trafficData = [
        1316, 1317, 2017, 1394, 829, 1199, 1462, 1736,
        1657, 1306, 1307, 1388, 1394, 1554, 1877, 1998,
        2172, 1294, 1260, 1636, 1179, 2128, 1358, 2035,
        1145, 1378, 1464, 1368, 1658, 1992, 1404, 1981,
        2541, 1692, 1934, 3694, 2771, 4174, 2383, 3671,
        3652, 2529, 2005, 1365, 1226, 1312, 2074, 2739,
        6775, 1563, 2271, 2051, 5649, 2027, 1897, 2145,
        3211, 13875, 2844, 2154, 1753, 574
      ];
      
      // Real attack data (blocked requests)
      const attackData = [
        14588, 778, 1223, 2336, 885, 1193, 416, 1303,
        3274, 888, 2614, 590, 896, 1008, 688, 829,
        2325, 442, 938, 707, 1277, 1401, 2532, 1547,
        5576, 2305, 1988, 1791, 2202, 4093, 3716, 2268,
        2893, 1114, 1842, 136444, 1910, 4477, 4017, 18167,
        896794, 1862, 3361, 3409, 3171, 6132, 4542, 5574,
        82682, 5682, 3634, 5011, 32198, 3662, 16482, 6790,
        31188, 3752, 4446, 1196, 947, 592
      ];
      
      trafficChartInstance.current = new Chart(ctx, {
        type: 'line',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Legitimate Traffic',
              data: trafficData,
              borderColor: 'rgba(0, 149, 255, 1)',
              backgroundColor: 'rgba(0, 149, 255, 0.1)',
              borderWidth: 2,
              tension: 0.4,
              fill: true
            },
            {
              label: 'Blocked Attacks',
              data: attackData,
              borderColor: 'rgba(255, 99, 132, 1)',
              backgroundColor: 'rgba(255, 99, 132, 0.1)',
              borderWidth: 2,
              tension: 0.4,
              fill: true
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'top',
              labels: {
                color: 'rgba(255, 255, 255, 0.7)',
                font: {
                  size: 12
                }
              }
            },
            tooltip: {
              mode: 'index',
              intersect: false,
              callbacks: {
                label: function(context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    label += context.parsed.y.toLocaleString();
                  }
                  return label;
                }
              }
            }
          },
          scales: {
            y: {
              type: 'logarithmic',
              grid: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              ticks: {
                color: 'rgba(255, 255, 255, 0.7)',
                callback: function(value) {
                  if (value === 10 || value === 100 || value === 1000 || value === 10000 || value === 100000 || value === 1000000) {
                    return value.toLocaleString();
                  }
                  return '';
                }
              }
            },
            x: {
              grid: {
                color: 'rgba(255, 255, 255, 0.1)'
              },
              ticks: {
                color: 'rgba(255, 255, 255, 0.7)',
                maxRotation: 45,
                minRotation: 45,
                callback: function(val, index) {
                  // Show only every 7th label to avoid crowding
                  return index % 7 === 0 ? this.getLabelForValue(val) : '';
                }
              }
            }
          }
        }
      });
    }
    
    return () => {
      if (trafficChartInstance.current) {
        trafficChartInstance.current.destroy();
      }
    };
  }, []);

  // Custom attack data for the map
  // Custom attack data for the map
const attackMapData = {
    name: "Attack Origins",
    type: "bubble",
    locations: [
      {
        name: "United States",
        latitude: 37.0902,
        longitude: -95.7129,
        relative_size: 50
      },
      {
        name: "Ireland",
        latitude: 53.1424,
        longitude: -7.6921,
        relative_size: 3
      },
      {
        name: "France",
        latitude: 46.2276,
        longitude: 2.2137,
        relative_size: 3
      },
      {
        name: "Germany",
        latitude: 51.1657,
        longitude: 10.4515,
        relative_size: 3
      },
      {
        name: "Canada",
        latitude: 56.1304,
        longitude: -106.3468,
        relative_size: 1
      },
      {
        name: "Russia",
        latitude: 61.5240,
        longitude: 105.3188,
        relative_size: 1
      },
      {
        name: "United Kingdom",
        latitude: 55.3781,
        longitude: -3.4360,
        relative_size: 1
      },
      {
        name: "Netherlands",
        latitude: 52.1326,
        longitude: 5.2913,
        relative_size: 1
      },
      {
        name: "Indonesia",
        latitude: -0.7893,
        longitude: 113.9213,
        relative_size: 0.5
      },
      {
        name: "Seychelles",
        latitude: -4.6796,
        longitude: 55.4920,
        relative_size: 0.5
      }
    ]
  };

  const handleMapStyleLoad = (loaded) => {
    setMapLoaded(loaded);
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div 
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t("investor_pitch.traction.title", "Explosive Demand Without Marketing")}
        </h2>
        <p className={styles.subtitle}>
          {t("investor_pitch.traction.subtitle", "60 days of organic growth proves the market is ready for Allegory")}
        </p>
      </motion.div>

      <div className={styles.content}>
        <motion.div 
          className={styles.metricsContainer}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          <motion.div 
            className={styles.metricCard}
            variants={ANIMATION_VARIANTS.stagger.item}
          >
            <div className={styles.metricIcon}>
              <FiUsers />
            </div>
            <div className={styles.metricContent}>
              <h3 className={styles.metricValue}>1.5 M+</h3>
              <p className={styles.metricLabel}>
                {t("investor_pitch.traction.metrics.visitors", "Total Requests")}
              </p>
              {/* <span className={styles.metricSubtext}>
                {t("investor_pitch.traction.metrics.visitors_subtext", "In just 60 days")}
              </span> */}
            </div>
          </motion.div>

          <motion.div 
            className={styles.metricCard}
            variants={ANIMATION_VARIANTS.stagger.item}
          >
            <div className={styles.metricIcon}>
              <FiShield />
            </div>
            <div className={styles.metricContent}>
              <h3 className={styles.metricValue}>1.36 M</h3>
              <p className={styles.metricLabel}>
                {t("investor_pitch.traction.metrics.attacks", "Attacks Blocked")}
              </p>
              {/* <span className={styles.metricSubtext}>
                {t("investor_pitch.traction.metrics.attacks_subtext", "They fear our disruption")}
              </span> */}
            </div>
          </motion.div>

          <motion.div 
            className={styles.metricCard}
            variants={ANIMATION_VARIANTS.stagger.item}
        >
            <div className={styles.metricIcon}>
            <FiBarChart2 />
            </div>
            <div className={styles.metricContent}>
            <h3 className={styles.metricValue}>137.8 K</h3>
            <p className={styles.metricLabel}>
                {t("investor_pitch.traction.metrics.legitimate_requests", "Legitimate Requests")}
            </p>
            {/* <span className={styles.metricSubtext}>
                {t("investor_pitch.traction.metrics.legitimate_requests_subtext", "Strong user engagement")}
            </span> */}
            </div>
          </motion.div>

          <motion.div 
            className={styles.metricCard}
            variants={ANIMATION_VARIANTS.stagger.item}
          >
            <div className={styles.metricIcon}>
              <FiMessageSquare />
            </div>
            <div className={styles.metricContent}>
              <h3 className={styles.metricValue}>600+</h3>
              <p className={styles.metricLabel}>
                {t("investor_pitch.traction.metrics.quotes", "Insurance Quotes")}
              </p>
              {/* <span className={styles.metricSubtext}>
                {t("investor_pitch.traction.metrics.quotes_subtext", "Strong purchase intent")}
              </span> */}
            </div>
          </motion.div>

          <motion.div 
            className={styles.metricCard}
            variants={ANIMATION_VARIANTS.stagger.item}
          >
            <div className={styles.metricIcon}>
              <FiDollarSign />
            </div>
            <div className={styles.metricContent}>
              <h3 className={styles.metricValue}>$2.5 M</h3>
              <p className={styles.metricLabel}>
                {t("investor_pitch.traction.metrics.potential_gwp", "Potential Annual GWP")}
              </p>
              {/* <span className={styles.metricSubtext}>
                {t("investor_pitch.traction.metrics.potential_gwp_subtext", "At current quote rate")}
              </span> */}
            </div>
          </motion.div>
        </motion.div>

        <div className={styles.visualizationsContainer}>
          <motion.div 
            className={styles.chartSection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <div className={styles.chartHeader}>
              <h3 className={styles.chartTitle}>
                {t("investor_pitch.traction.chart.title", "Website Traffic & Attack Attempts")}
              </h3>
              <div className={styles.chartSubtitle}>
                <FiLock className={styles.securityIcon} />
                <span>
                  {t("investor_pitch.traction.chart.subtitle", "Data from AWS WAF Logs")}
                </span>
              </div>
            </div>
            <div className={styles.chartContainer}>
              <canvas ref={trafficChartRef}></canvas>
            </div>
          </motion.div>

          <motion.div 
            className={styles.mapSection}
            variants={ANIMATION_VARIANTS.fadeIn}
          >
            <div className={styles.mapHeader}>
              <h3 className={styles.mapTitle}>
                {t("investor_pitch.traction.map.title", "Attack Origins")}
              </h3>
              <div className={styles.mapSubtitle}>
                <FiGlobe className={styles.globeIcon} />
                <span>
                  {t("investor_pitch.traction.map.subtitle", "94% of attacks originate from the US")}
                </span>
              </div>
            </div>
            <div className={styles.mapContainer}>
              {!mapLoaded && (
                <div className={styles.mapLoading}>
                  <div className={styles.loadingSpinner}></div>
                </div>
              )}
              <MapComponent 
                data={attackMapData}
                category="attack_origins"
                onStyleLoad={handleMapStyleLoad}
              />
            </div>
          </motion.div>
        </div>

        <motion.div 
          className={styles.insightsContainer}
          variants={ANIMATION_VARIANTS.stagger.container}
        >
          <motion.div 
            className={styles.insightCard}
            variants={ANIMATION_VARIANTS.stagger.item}
          >
            <h3 className={styles.insightTitle}>
              {t("investor_pitch.traction.insights.zero_marketing.title", "Zero Paid Marketing")}
            </h3>
            <p className={styles.insightText}>
              {t("investor_pitch.traction.insights.zero_marketing.text", "All growth is organic through social media and word of mouth")}
            </p>
          </motion.div>

          <motion.div 
            className={styles.insightCard}
            variants={ANIMATION_VARIANTS.stagger.item}
          >
            <h3 className={styles.insightTitle}>
              {t("investor_pitch.traction.insights.license.title", "Becoming a Licensed Insurer")}
            </h3>
            <p className={styles.insightText}>
              {t("investor_pitch.traction.insights.license.text", "Not an MGA or broker - we're building a full-stack insurance carrier")}
            </p>
          </motion.div>

          <motion.div 
            className={styles.insightCard}
            variants={ANIMATION_VARIANTS.stagger.item}
          >
            <h3 className={styles.insightTitle}>
              {t("investor_pitch.traction.insights.projection.title", "Projected Growth")}
            </h3>
            <p className={styles.insightText}>
              {t("investor_pitch.traction.insights.projection.text", "Current demand is just 0.01% of our $3.2B revenue target")}
            </p>
          </motion.div>
        </motion.div>

        <motion.div 
          className={styles.conclusionCard}
          variants={ANIMATION_VARIANTS.scaleIn}
        >
          <div className={styles.conclusionIcon}>
            <FiTrendingUp />
          </div>
          <div className={styles.conclusionContent}>
            <h3 className={styles.conclusionTitle}>
              {t("investor_pitch.traction.conclusion.title", "Why People Want Us")}
            </h3>
            <p className={styles.conclusionText}>
              {t("investor_pitch.traction.conclusion.text", "Our 25% guaranteed savings and autonomous underwriting are disrupting the industry. The attacks on our platform prove incumbents are afraid.")}
            </p>
            <div className={styles.dataCapabilities}>
              <h4 className={styles.dataCapabilitiesTitle}>
                {t("investor_pitch.traction.data_capabilities.title", "Advanced Data Collection")}
              </h4>
              <p className={styles.dataCapabilitiesText}>
                {t("investor_pitch.traction.data_capabilities.text", "We capture every URI path, HTTP method, IP address, user agent, JA3/JA4 fingerprints, device details, and geographic data to build comprehensive risk profiles.")}
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default TractionSlide;