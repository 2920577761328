// File: frontend/src/config/constants.js

export const APP_ENV = process.env.REACT_APP_APP_ENV || 'dev';
export const API_URL = process.env.REACT_APP_API_URL;
export const APP_URL = process.env.REACT_APP_APP_URL;
export const DOCS_URL = process.env.REACT_APP_DOCS_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
export const S3_PLATO = process.env.REACT_APP_S3_PLATO;
export const S3_IOT = process.env.REACT_APP_S3_IOT;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;

export const RETRY_CONFIG = {
  MAX_RETRIES : 3,
  RETRY_DELAY : 1000,
};

export const AUTH_CONFIG = {
  TOKEN_REFRESH_THRESHOLD: 15 * 60 * 1000,        // 15 minutes buffer
  ACCESS_TOKEN_EXPIRY: 24 * 60 * 60 * 1000,       // 24 hours
  REFRESH_TOKEN_EXPIRY: 30 * 24 * 60 * 60 * 1000, // 30 days
  ENABLE_AUTO_LOGOUT: false,                      // Auto logout flag
  INACTIVITY_TIMEOUT: 75 * 60 * 1000,             // 75 minutes (longer than token life)
  ACTIVITY_CHECK_INTERVAL: 5 * 60 * 1000,         // Every 5 minutes
};

export const VALIDATION = {
  PHONE: {
    COUNTRY_CODE_REGEX: /^\+\d{1,3}$/,
    AREA_CODE_REGEX: /^\d{3}$/,
    NUMBER_REGEX: /^\d{7}$/
  },
  PASSWORD: {
    MIN_LENGTH: 12,
  },
  OTP: {
    LENGTH: 7
  }
};

// API Endpoints based on the Lambda functions
export const API_ENDPOINTS = {
  AUTH: {
    SIGNUP: {
      INITIATE: '/auth/signup/initiate',
      VERIFY_MOBILE: '/auth/signup/verify-mobile',
      VERIFY_EMAIL: '/auth/signup/verify-email',
      RESEND_VERIFICATION: '/auth/signup/verify-email',
      VERIFY_USER: '/auth/signup/verify-user',
      COMPLETE: '/auth/signup/complete'
    },
    SIGNIN: {
      INITIATE: '/auth/signin/initiate',
      VERIFY: '/auth/signin/verify',
      CHANGE_PASSWORD: '/auth/signin/change-password'
    },
    SIGNOUT: '/auth/signout',
    REFRESH_TOKEN: '/auth/refresh-token',
  },
  LANGUAGES: {
    LIST: '/languages',
    UPDATE: (id) => `/languages/${id}`
  },
  ENGAGEMENT: {
    SUBSCRIBE: '/engagement/subscribe/newsletter',
    UNSUBSCRIBE: '/engagement/unsubscribe/newsletter',
    JOIN_SUBMIT: '/engagement/join/submit',
    CONTACT_SUBMIT: '/engagement/contact/submit',
    LIST_APPLICATIONS: '/engagement/list-applications',
    DELETE_APPLICATION: '/engagement/delete-application'
  },
  SYSTEM: {
    HEALTH_CHECKS: '/system/health-checks'
  },
  DOCS: {
    API: '/docs',
    SWAGGER: '/docs/swagger',
    UI: '/docs/ui'
  },
  AGENTS: {
    CHAT: {
      INITIATE: '/agents/chat/initiate',
      MESSAGE: '/agents/chat/message',
      HISTORY: '/agents/chat/history',
      GET_CHAT: '/agents/chat',
      GET: (chatId) => `/agents/chat/${chatId}`
    }
  },
  QUOTE: {
    ABANDONED: '/quote/abandoned',
    ABANDON: '/quote/abandon',
    COMPLETE: '/quote/complete',
    CREATE_SAVINGS: '/quote/create-savings',
    GENERATE_QNR: '/quote/generate-qnr',
    GET_MESSAGES: (quoteId) => `/quote/${quoteId}/messages`,
    GET_QUOTE: (quoteId) => `/quote/${quoteId}`,
    SEARCH_QUOTE: '/quote/search',
    GET_SAVINGS_PRODUCT: '/quote/get-savings-session-product',
    GET_SAVINGS_PRODUCTS: '/quote/get-savings-session-products',
    INITIATE: '/quote/initiate',
    LOCK: '/quote/lock',
    PAY: '/quote/pay',
    BIND: '/quote/bind',
    EXPLAIN: '/quote/explain',
    SEND_MESSAGE: '/quote/send-message',
    SYNC_DYNAMO_VARS: '/quote/sync-dynamo-vars',
    TRACK_SAVINGS: '/quote/track-savings-session',
    UPDATE: '/quote/update',
    UPDATE_SAVINGS: '/quote/update-savings',
    VERIFY_EMAIL: '/quote/verify-email',
    VERIFY_PHONE: '/quote/verify-phone',
    VERIFY_QNR: '/quote/verify-qnr',
  },
  USER: {
    CREATE: '/user/create',
    GET: '/user/get',
    UPDATE: '/user/update',
    SEARCH: '/user/search',
    DELETE: '/user/delete',
    PURGE: '/user/purge',
    DELETE_ACCOUNT: '/users/delete',
    UPDATE_PERMISSIONS: '/user/update-permissions',
    ADDRESS: {
      ADD: '/user/add-address',
      UPDATE: '/user/update-address',
      DELETE: '/user/delete-address'
    },
    CONNECTION: {
      CREATE: '/user/create-connection',
      ACCEPT: '/user/accept-connection',
      GET_ALL: '/user/get-connections'
    }
  },
  VEHICLE: {
    INITIATE: '/vehicle/initiate',
    BULK_INITIATE: '/vehicle/bulk-initiate',
    GET: '/vehicle/get',
    SEARCH: '/vehicle/search',
    DELETE: '/vehicle/delete',
    PURGE: '/vehicle/purge',
    STATUS: '/vehicle/status',
    LINK_OWNER: '/vehicle/link-owner',
    LINK_POLICY: '/vehicle/link-policy',
    HISTORY: '/vehicle/history',
    PATCH_MAIN: '/vehicle/patch-main',
    GET_NHTSA: '/vehicle/get-nhtsa',
    GET_NHTSA_RECALLS: '/vehicle/get-nhtsa-recalls',
    GET_MARKET_DATA: '/vehicle/get-market-data',
    GET_RECALL_INFO: '/vehicle/get-recall-info',
    GET_DIMENSIONS: '/vehicle/get-dimensions',
    GET_FACTORY_INFO: '/vehicle/get-factory-info',
    GET_PERFORMANCE_SPECS: '/vehicle/get-performance-specs',
    UPDATE: {
      AUTOMATION_LEVEL: '/vehicle/update-automation-level',
      DETAILS: '/vehicle/update-details',
      SAFETY_FEATURES: '/vehicle/update-safety-features',
      ADAS_FEATURES: '/vehicle/update-adas-features',
      MODIFICATIONS: '/vehicle/update-modifications',
      USAGE: '/vehicle/update-usage',
      ODOMETER_READINGS: '/vehicle/update-odometer-readings',
      MAINTENANCE: '/vehicle/update-maintenance',
      TELEMATICS: '/vehicle/update-telematics',
      MARKET_INTEL: '/vehicle/update-market-intel',
      VALUATION: '/vehicle/update-valuation',
      PURCHASE: '/vehicle/update-purchase',
      OWNERSHIP: '/vehicle/update-ownership',
      IMAGES: '/vehicle/update-images',
      RISK: '/vehicle/update-risk',
      CLAIMS: '/vehicle/update-claims',
      POLICIES: '/vehicle/update-policies',
      FEEDBACK: '/vehicle/update-feedback',
      RECALLS: '/vehicle/update-recalls',
      SEARCH_INDEX: '/vehicle/update-search-index'
    },
    BULK_UPDATE: '/vehicle/bulk-update'
  },
  PROPERTY: {
    INITIATE: '/property/initiate',
    BULK_INITIATE: '/property/bulk-initiate',
    GET: '/property/get',
    SEARCH: '/property/search',
    DELETE: '/property/delete',
    PURGE: '/property/purge',
    STATUS: '/property/status',
    LINK_OWNER: '/property/link-owner',
    LINK_POLICY: '/property/link-policy',
    LINK_ACCOUNT: '/property/link-account',
    LINK_POLICYHOLDER: '/property/link-policyholder',
    HISTORY: '/property/history',
    PATCH_MAIN: '/property/patch-main',
    GET_FLOOD_RISK: '/property/get-flood-risk',
    GET_WILDFIRE_RISK: '/property/get-wildfire-risk',
    GET_EARTHQUAKE_RISK: '/property/get-earthquake-risk',
    GET_REPLACEMENT_COST: '/property/get-replacement-cost',
    GET_MARKET_VALUE: '/property/get-market-value',
    GET_TERRITORY_INFO: '/property/get-territory-info',
    VALIDATE_ADDRESS: '/property/validate-address',
    ADD: {
      ADDRESS: '/property/add-address',
      BUILDING: '/property/add-building',
      UNIT: '/property/add-unit',
      OCCUPANT: '/property/add-occupant',
      MORTGAGE: '/property/add-mortgage',
      CLAIM: '/property/add-claim'
    },
    REMOVE: {
      ADDRESS: '/property/remove-address',
      BUILDING: '/property/remove-building',
      UNIT: '/property/remove-unit',
      OCCUPANT: '/property/remove-occupant',
      MORTGAGE: '/property/remove-mortgage'
    },
    UPDATE: {
      ADDRESS: '/property/update-address',
      BUILDING: '/property/update-building',
      UNIT: '/property/update-unit',
      BUILDING_SYSTEMS: '/property/update-building-systems',
      SAFETY_FEATURES: '/property/update-safety-features',
      RISK_AND_INSURANCE: '/property/update-risk-and-insurance',
      REPLACEMENT_COST: '/property/update-replacement-cost',
      OCCUPANCY_AND_BUSINESS: '/property/update-occupancy-and-business',
      MORTGAGE: '/property/update-mortgage',
      EXTERNAL_FEATURES: '/property/update-external-features',
      ADDITIONAL_DETAILS: '/property/update-additional-details',
      ACCOUNTS: '/property/update-accounts',
      POLICIES: '/property/update-policies',
      USERS: '/property/update-users',
      POLICYHOLDERS: '/property/update-policyholders',
      LOCATIONS: '/property/update-locations',
      TERRITORY: '/property/update-territory',
      OCCUPANTS: '/property/update-occupants',
      RISK_ASSESSMENT: '/property/update-risk-assessment',
      CLAIMS: '/property/update-claims',
      RENTAL: '/property/update-rental',
      SEARCH_INDEX: '/property/update-search-index'
    },
    BULK_UPDATE: '/property/bulk-update'
  },
  TRIP: {
    INITIATE: '/trip/initiate',
    START: '/trip/start',
    END: '/trip/end',
    UPDATE_POSITION: '/trip/update-position',
    BULK_UPLOAD: '/trip/bulk-upload',
    GET: '/trip/get',
    SEARCH: '/trip/search',
    DELETE: '/trip/delete',
    PURGE: '/trip/purge',
    STATUS: '/trip/status',
    LINK_VEHICLE: '/trip/link-vehicle',
    HISTORY: '/trip/history',
    PATCH_MAIN: '/trip/patch-main',
    USER_TRIPS: '/trip/user-trips',
    VEHICLE_TRIPS: '/trip/vehicle-trips',
    DEVICE_TRIPS: '/trip/device-trips',
    DATE_RANGE_TRIPS: '/trip/date-range-trips',
    MERGE_TRIPS: '/trip/merge-trips',
    SPLIT_TRIP: '/trip/split-trip',
    ADD_FEEDBACK: '/trip/add-feedback',
    ADD_IMAGE: '/trip/add-image',
    UPDATE: {
      FILTERED_LOCATIONS: '/trip/update-filtered-locations',
      FILTERED_LOCATIONS_AGGS: '/trip/update-filtered-locations-aggs',
      EVENTS: '/trip/update-events',
      SPEEDS: '/trip/update-speeds',
      ENGAGEMENT: '/trip/update-engagement',
      DECILES: '/trip/update-deciles',
      PROCESSING_ERRORS: '/trip/update-processing-errors',
      STATS: '/trip/update-stats',
      SEARCH_INDEX: '/trip/update-search-index',
      USER_SWIPE: '/trip/update-user-swipe'
    },
    CHANGE: {
      DRIVER: '/trip/change-driver',
      TRIP_TYPE: '/trip/change-trip-type',
      TRANSPORTATION_MODE: '/trip/change-transportation-mode'
    },
    GET_EVENTS: '/trip/get-events',
    GET_ROUTE: '/trip/get-route',
    GET_SCORES: '/trip/get-scores',
    GET_STATS: '/trip/get-stats',
    BULK_UPDATE: '/trip/bulk-update'
  },
  ENTERPRISE: {
    ACCOUNT: {
      CREATE: '/enterprise/account/create',
      UPDATE: '/enterprise/account/update',
      DELETE: '/enterprise/account/delete'
    },
    AGREEMENT: {
      CREATE: '/enterprise/agreement/create',
      UPDATE: '/enterprise/agreement/update',
      DELETE: '/enterprise/agreement/delete'
    },
    PRODUCT: {
      CREATE: '/enterprise/product/create',
      GET: '/enterprise/product/get',
      UPDATE: '/enterprise/product/update',
      DELETE: '/enterprise/product/delete',
      LIST: '/enterprise/product/list'
    },
    PRODUCT_FEATURE: {
      CREATE: '/enterprise/product-feature/create',
      GET: '/enterprise/product-feature/get',
      LIST: '/enterprise/product-feature/list'
    },
    USER: {
      VERIFY: '/enterprise/user/verify'
    },
    NEWSLETTER: {
      LIST: '/enterprise/newsletter/list',
      SEND: '/enterprise/newsletter/send'
    },
    COMMUNICATION: {
      SEND_EMAIL: '/enterprise/communication/send-email',
      SEND_SMS: '/enterprise/communication/send-sms'
    },
    PITCH: {
      VERIFY_ACCESS: '/enterprise/pitch/verify-access',
      ADD_ACCESS: '/enterprise/pitch/add-access',
      GRANT_ACCESS: '/enterprise/pitch/grant-access',
      REVOKE_ACCESS: '/enterprise/pitch/revoke-access',
      UPDATE_ACCESS_COUNT: '/enterprise/pitch/update-access-count',
      GET_ACCESS_LIST: '/enterprise/pitch/get-access-list'
    },
    DATASET: {
      CREATE: '/enterprise/dataset/create',
      UPDATE: '/enterprise/dataset/update',
      DELETE: '/enterprise/dataset/delete'
    },
    REPORT: {
      CREATE: '/enterprise/report/create',
      UPDATE: '/enterprise/report/update',
      DELETE: '/enterprise/report/delete'
    },
    EMAIL_TEMPLATE: {
      CREATE: '/enterprise/email-template/create',
      UPDATE: '/enterprise/email-template/update',
      DELETE: '/enterprise/email-template/delete'
    },
    SMS_TEMPLATE: {
      CREATE: '/enterprise/sms-template/create',
      UPDATE: '/enterprise/sms-template/update',
      DELETE: '/enterprise/sms-template/delete'
    }
  },
  MASTER: {
    GEO: {
      CREATE: '/master/geo/create',
      UPDATE: '/master/geo/update',
      DELETE: '/master/geo/delete',
      GET: '/master/geo/get',
    },
    LOOKUP: {
      CREATE: '/master/lookup/create',
      UPDATE: '/master/lookup/update',
      DELETE: '/master/lookup/delete',
      GET: '/master/lookup/get',
    },
    LANGUAGE: {
      CREATE: '/master/language/create',
      UPDATE: '/master/language/update',
      DELETE: '/master/language/delete',
      GET: '/master/language/get',
    }
  }
};

export const ENUMS = {
  // category_type_id=44
  PRIMARY_LANGUAGE: {
    'es-es': 3231,
    'es-mx': 3232,
    'es-ar': 3233,
    'fr-fr': 3234,
    'fr-ca': 3235,
    'it-it': 3236,
    'pt-pt': 3237,
    'pt-br': 3238,
    'ro-ro': 3239,
    'ca-es': 3240,
    'gl-es': 3241,
    'oc-fr': 3242,
    'en-us': 3243,
    'en-gb': 3244,
    'en-ca': 3245,
    'en-au': 3246,
    'de-de': 3247,
    'de-at': 3248,
    'nl-nl': 3249,
    'nl-be': 3250,
    'sv-se': 3251,
    'da-dk': 3252,
    'no-no': 3253,
    'is-is': 3254,
    'af-za': 3255,
    'fy-nl': 3256,
    'lb-lu': 3257,
    'zh-cn': 3258,
    'zh-tw': 3259,
    'zh-hk': 3260,
    'zh-sg': 3261,
    'bo-cn': 3262,
    'my-mm': 3263,
    'ru-ru': 3264,
    'pl-pl': 3265,
    'cs-cz': 3266,
    'sk-sk': 3267,
    'uk-ua': 3268,
    'bg-bg': 3269,
    'hr-hr': 3270,
    'sr-rs': 3271,
    'sl-si': 3272,
    'hi-in': 3273,
    'ar-sa': 3274,
    'ar-eg': 3275,
    'he-il': 3276,
    'ja-jp': 3277,
    'ko-kr': 3278,
    'tr-tr': 3279,
    'fi-fi': 3280,
    'hu-hu': 3281,
    'el-gr': 3282,
    'th-th': 3283,
    'id-id': 3284,
    'ms-my': 3285,
    'vi-vn': 3286,
  },
  // category_type_id=16
  ORGANIZATION_SIZE: {
    '1_9': 315,
    '10_49': 316,
    '50_99': 317,
    '100_249': 318,
    '250_499': 319,
    '500_999': 320,
    '1000_4999': 321,
    '5000_plus': 322
  },
  // category_type_id=22
  DATASET_LOCATION: {
    'on-premise': 358,
    'cloud': 359,
    'cloud_aws': 360,
    'cloud_azure': 361,
    'cloud_gcp': 362,
    'hybrid': 363
  },
  // category_type_id=23
  DATASET_REGION: {
    'us_east_1': 364,
    'us_east_2': 365,
    'us_west_1': 366,
    'us_west_2': 367,
    'af_south_1': 368,
    'ap_east_1': 369,
    'ap_southeast_3': 370,
    'ap_south_1': 371,
    'ap_northeast_3': 372,
    'ap_northeast_2': 373,
    'ap_southeast_1': 374,
    'ap_southeast_2': 375,
    'ap_northeast_1': 376,
    'ca_central_1': 377,
    'eu_central_1': 378,
    'eu_west_1': 379,
    'eu_west_2': 380,
    'eu_south_1': 381,
    'eu_west_3': 382,
    'eu_north_1': 383,
    'me_south_1': 384,
    'me_central_1': 385,
    'sa_east_1': 386,
  },
  // category_type_id=172
  USER_ROLE: {
    'guest': 2671,
    'standard': 2672,
    'admin': 2673,
    'super_admin': 2674,
    'manager': 2675,
    'developer': 2676,
    'underwriter': 2677,
    'senior_underwriter': 2678,
    'chief_underwriter': 2679,
    'actuary': 2680,
    'claims_adjuster': 2681,
    'claims_manager': 2682,
    'risk_analyst': 2683,
    'insurance_agent': 2684,
    'insurance_broker': 2685,
    'policy_administrator': 2686,
    'compliance_officer': 2687,
    'fraud_investigator': 2688,
    'loss_control_specialist': 2689,
    'reinsurance_specialist': 2690,
    'analyst': 2691,
    'data_scientist': 2692,
    'business_intelligence_analyst': 2693,
    'ceo': 2694,
    'cfo': 2695,
    'coo': 2696,
    'cto': 2697,
    'cio': 2698,
    'cro': 2699,
  },
  // category_type_id=15
  USER_VERIFICATION_STATUS: {
    'unverified': 309,
    'pending': 310,
    'verified': 311,
    'rejected': 312,
    'expired': 313,
    'suspended': 314
  },
  // category_type_id=199
  SUPER_ADMIN_VERIFICATION_STATUS: {
    'pending': 3287,
    'verified': 3288,
    'rejected': 3289,
    'suspended': 3290
  },
  // category_type_id=166
  PREFERRED_MEASUREMENT_UNIT: {
    'imperial': 2360,
    'metric': 2359
  },
  // category_type_id=167
  USER_TYPE: {
    'enterprise': 2361,
    'mobile_app': 2362,
    'insurance': 2363,
  },
  // VERIFICATION_STATUS: {
  //   'unverified': 309,
  //   'pending': 310,
  //   'verified': 311,
  //   'rejected': 312,
  //   'expired': 313,
  //   'suspended': 314
  // },
  // ADMIN_VERIFICATION_STATUS: {
  //   'pending': 3287,
  //   'verified': 3288,
  //   'rejected': 3289,
  //   'suspended': 3290
  // }
};
