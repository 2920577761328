// File: frontend/src/pages/InvestorPitch/components/slides/AI1Engineering/AI1Engineering.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './AI1Engineering.module.css';

const AI1Engineering = () => {
  const { t } = useLanguage();
  
  const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
  };

  const components = [
    {
      id: 'synthetic_universe',
      color: 'var(--color-blue-310)',
      metrics: {
        iterations: 4380,
        duration: '1,815 hours or 75 days non-stop',
        tokensPerHour: 140000,
        modeledAssets: 'Quotes, Policies, Claims, etc.',
        // methodology: 'Monte Carlo + LLMs'
      }
    },
    {
      id: 'simulation_engine',
      color: 'var(--color-green-310)',
      metrics: {
        infra: 'Allegory Plato & Socrates Learning Modules',
        coreModel: 'Multi Modal Actuarial Distributions powered by LLM and Monte Carlo',
        // iterations: 4380,
        // parallelRuns: 12,
        scale: 'Full-stack operations',
        objective: 'Real-time cost minimization'
      }
    },
    {
      id: 'real_world_data',
      color: 'var(--color-orange-310)',
      metrics: {
        iot: '5 years of sensor data',
        lifecycle: '1.5 million insurance quotes',
        claims: '1+ million claims history',
        market: '100 M coverage price points',
        thirdParty: 'Licensed datasets'
      }
    },
    {
      id: 'llm_generated_code',
      color: 'var(--color-yellow-310)',
      metrics: {
        automation: 'Generated via LLM',
        loc: '291,815 in Production',
        impact: 'Human-level complexity',
        modules: '28 product modules',
        why: 'Impossible to build manually',
        humanEquivalent: '30 engineers × 5 years'
      }
    }
  ];

  const renderMetrics = (metrics, componentId) => {
    return Object.entries(metrics).map(([key, value]) => {
      const isNumber = typeof value === 'number';
      return (
        <div key={key} className={styles.metric}>
          <span className={styles.metricLabel}>
            {t(`investor_pitch.ai1_engineering.components.${key}.label`, key)}
          </span>
          <span className={styles.metricValue}>
            {isNumber ? formatNumber(value) : value}
          </span>
        </div>
      );
    });
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      <motion.div className={styles.header} variants={ANIMATION_VARIANTS.fadeIn}>
        <h2 className={styles.title}>
          {t('investor_pitch.ai1_engineering.title', 'AI-1 Engineering')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.ai1_engineering.subtitle', 'Building the world\'s most advanced insurance AI')}
        </p>
      </motion.div>

      <motion.div 
        className={styles.metricsGrid}
        variants={ANIMATION_VARIANTS.stagger.container}
      >
        {components.map((component, index) => (
          <motion.div
            key={component.id}
            className={styles.componentCard}
            variants={ANIMATION_VARIANTS.stagger.item}
            custom={index}
            style={{
              '--component-color': component.color
            }}
          >
            <div className={styles.componentHeader}>
              <h3 className={styles.componentName}>
                {t(`investor_pitch.ai1_engineering.components.${component.id}.name`, component.id.replace(/_/g, ' '))}
              </h3>
            </div>

            <div className={styles.metricsContainer}>
              {renderMetrics(component.metrics, component.id)}
            </div>
          </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default AI1Engineering;