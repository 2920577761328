// File: frontend/src/pages/InvestorPitch/components/slides/RevenueProjection/RevenueProjection.js

import React from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './RevenueProjection.module.css';

const RevenueProjection = () => {
  const { t, currentLanguage } = useLanguage();

  const cycles = [
    { 
      id: 'first',  
      years: t('investor_pitch.revenue_projection.cycle.first.years'), 
      value: 3.2,   
      displayValue: t('investor_pitch.revenue_projection.cycle.first.value')
    },
    { 
      id: 'second', 
      years: t('investor_pitch.revenue_projection.cycle.second.years'), 
      value: 32,    
      displayValue: t('investor_pitch.revenue_projection.cycle.second.value')
    },
    { 
      id: 'third',  
      years: t('investor_pitch.revenue_projection.cycle.third.years'), 
      value: 160,   
      displayValue: t('investor_pitch.revenue_projection.cycle.third.value')
    },
    { 
      id: 'fourth', 
      years: t('investor_pitch.revenue_projection.cycle.fourth.years'), 
      value: 480,   
      displayValue: t('investor_pitch.revenue_projection.cycle.fourth.value')
    },
    { 
      id: 'fifth',  
      years: t('investor_pitch.revenue_projection.cycle.fifth.years'), 
      value: 1000,  
      displayValue: t('investor_pitch.revenue_projection.cycle.fifth.value')
    }
  ];

  // For simplicity, we'll just do linear scaling from minValue→maxValue to 40px→300px
  const minValue = 3.2;
  const maxValue = 1000;
  const minHeightPx = 40;
  const maxHeightPx = 300;

  const getBarHeight = (val) => {
    let ratio = (val - minValue) / (maxValue - minValue);
    ratio = Math.max(0, Math.min(1, ratio));
    return minHeightPx + ratio * (maxHeightPx - minHeightPx);
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      {/* Title & subtitle */}
      <motion.div
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.title}>
          {t('investor_pitch.revenue_projection.title')}
        </h2>
        <p className={styles.subtitle}>
          {t('investor_pitch.revenue_projection.subtitle')}
        </p>
      </motion.div>

      {/* The bar chart */}
      <div className={styles.chartWrapper}>
        <div className={styles.chartContainer}>
          {cycles.map((cycle, index) => {
            const barHeight = getBarHeight(cycle.value);

            return (
              <motion.div
                key={cycle.id}
                className={styles.cycleColumn}
                variants={ANIMATION_VARIANTS.stagger.item}
                custom={index}
              >
                <div className={styles.barContainer}>
                  <motion.div
                    className={styles.bar}
                    initial={{ height: 0 }}
                    animate={{ height: barHeight }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                  >
                    <span className={styles.barValue}>
                      {cycle.displayValue}
                    </span>
                  </motion.div>
                </div>
                <div className={styles.cycleLabel}>
                  <span className={styles.cycleName}>
                    {t(`investor_pitch.revenue_projection.cycle.${cycle.id}.name`)}
                  </span>
                  <span className={styles.cycleYears}>
                    {t('investor_pitch.revenue_projection.year_label', { years: cycle.years })}
                  </span>
                </div>
              </motion.div>
            );
          })}
        </div>
      </div>

      {/* EXPLANATION SECTION BELOW THE CHART */}
      <div className={styles.explanationContainer}>
        <h3 className={styles.explanationTitle}>
          {t('investor_pitch.revenue_projection.explanation.title')}
        </h3>
        <p className={styles.explanationText}>
          {t('investor_pitch.revenue_projection.explanation.text')}
        </p>

        <h4 className={styles.explanationSubtitle}>
          {t('investor_pitch.revenue_projection.explanation.subtitle')}
        </h4>
        <p className={styles.explanationText}>
          {t('investor_pitch.revenue_projection.explanation.subtext')}
        </p>
      </div>
    </motion.div>
  );
};

export default RevenueProjection;