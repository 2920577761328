// File: frontend/src/pages/InvestorPitch/components/slides/InvestmentOpportunitySlide/InvestmentOpportunitySlide.js

// Updated InvestmentOpportunitySlide.js

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useLanguage } from '../../../../../contexts/LanguageContext';
import { ANIMATION_VARIANTS } from '../../../constants/animations';
import styles from './InvestmentOpportunitySlide.module.css';
import { FiDollarSign, FiTarget, FiPieChart, FiCalendar, FiLayers } from 'react-icons/fi';

const InvestmentOpportunitySlide = () => {
  const { t } = useLanguage();
  const [activeTab, setActiveTab] = useState('strategy');

  const tabs = [
    { id: 'strategy', label: t('investor_pitch.investment_opportunity.tabs.strategy', 'Investment Strategy') },
    { id: 'structure', label: t('investor_pitch.investment_opportunity.tabs.structure', 'Capital Structure') },
    // { id: 'timeline', label: t('investor_pitch.investment_opportunity.tabs.timeline', 'Timeline & Milestones') }
  ];

  // Series A investment details
  const seriesADetails = {
    amount: '$50M',
    preMoneyValuation: '$1,003.8M',
    postMoneyValuation: '$1,053.8M',
    equityPercentage: '20-25%',
    targetMarkets: [
      'Canada: Ontario, Quebec, Alberta, British Columbia',
      'United States: Deliberately avoiding complex US markets (NY, CA, TX, FL) in initial phase',
      'United Kingdom: Relationship building with Lloyd\'s Syndicates'
    ],
    allocation: [
      { 
        category: t('investor_pitch.investment_opportunity.allocation.licenses', 'Dormant License Acquisition & Regulatory Capital'), 
        amount: '$25M',
        percentage: '50%',
        description: 'Focused on securing 1-3 strategic jurisdictions and satisfying initial regulatory capital requirements'
      },
      { 
        category: t('investor_pitch.investment_opportunity.allocation.technology', 'Technology Enhancement'), 
        amount: '$10M',
        percentage: '20%',
        description: 'AI model refinement for core markets, regulatory compliance adaptations, infrastructure scaling'
      },
      { 
        category: t('investor_pitch.investment_opportunity.allocation.market', 'Market Entry'), 
        amount: '$8M',
        percentage: '16%',
        description: 'Strategic partnership development, initial distribution channels, brand establishment'
      },
      { 
        category: t('investor_pitch.investment_opportunity.allocation.operations', 'Operations & Team'), 
        amount: '$5M',
        percentage: '10%',
        description: 'Key executive hires with insurance credentials, core operational team'
      },
      { 
        category: t('investor_pitch.investment_opportunity.allocation.legal', 'Legal & Regulatory'), 
        amount: '$2M',
        percentage: '4%',
        description: 'Specialized insurance counsel, compliance infrastructure'
      }
    ]
  };

  // Parallel capital raises
  const parallelCapital = [
    {
      category: t('investor_pitch.investment_opportunity.parallel.risk_capital', 'Insurance Risk Capital'),
      amount: '$100M',
      description: 'Structured as regulatory capital for insurance entity. Enables writing significant premium volume, which will linearly increase technology royalty revenue. May be raised through reinsurance partnerships or specialized insurance investors.'
    },
    {
      category: t('investor_pitch.investment_opportunity.parallel.marketing', 'Marketing & Distribution'),
      amount: '$50M',
      description: 'Partner empowerment initiatives, acquiring small to mid-size brokers to capture significant commercial line businesses in select lucrative markets. Market expansion to the UK where competition is much higher and sophisticated. Customer acquisition.'
    },
    {
      category: t('investor_pitch.investment_opportunity.parallel.rd', 'R&D for Health Insurance Expansion'),
      amount: '$50M',
      description: 'AI-2 computing infrastructure, training and enhancing current synthetic insurance. Health insurance product development. US market entry preparations for late 2025/early 2026.'
    }
  ];

  // Business model structure
  const businessModel = {
    technologyRoyalty: '5% of GWP',
    operationalExpense: '3% of GWP',
    totalExpenseRatio: '8% of GWP',
    industryAverage: '25-40% of GWP'
  };

  // Timeline milestones
  const timeline = [
    {
      phase: t('investor_pitch.investment_opportunity.timeline.near_term', 'Near-Term (0-6 months)'),
      milestones: [
        'Complete Series A fundraising',
        'Secure dormant licenses in initial jurisdictions',
        'Begin regulatory approval process'
      ]
    },
    {
      phase: t('investor_pitch.investment_opportunity.timeline.medium_term', 'Medium-Term (6-12 months)'),
      milestones: [
        'Launch initial insurance products in Canadian markets',
        'Secure parallel capital raises',
        'Begin AI-2 development'
      ]
    },
    {
      phase: t('investor_pitch.investment_opportunity.timeline.long_term', 'Long-Term (12-24 months)'),
      milestones: [
        'Expand to additional jurisdictions',
        'Launch health insurance products in US market',
        'Begin Series B preparations based on demonstrated traction'
      ]
    }
  ];

  const renderStrategyTab = () => (
    <div className={styles.tabContent}>
      <div className={styles.strategyHeader}>
        <div className={styles.strategyTitle}>
          <h3>{t('investor_pitch.investment_opportunity.strategy_title', 'Series A Investment Strategy')}</h3>
          <p>{t('investor_pitch.investment_opportunity.strategy_subtitle', 'Focused deployment to establish regulated insurance operations')}</p>
        </div>
        <div className={styles.strategyHighlights}>
          <div className={styles.highlightItem}>
            <span className={styles.highlightLabel}>{t('investor_pitch.investment_opportunity.amount_label', 'Series A')}</span>
            <span className={styles.highlightValue}>{seriesADetails.amount}</span>
          </div>
          {/* <div className={styles.highlightItem}>
            <span className={styles.highlightLabel}>{t('investor_pitch.investment_opportunity.valuation_label', 'Pre-Money')}</span>
            <span className={styles.highlightValue}>{seriesADetails.preMoneyValuation}</span>
          </div> */}
          <div className={styles.highlightItem}>
            <span className={styles.highlightLabel}>{t('investor_pitch.investment_opportunity.equity_range_label', 'Equity Range')}</span>
            <span className={styles.highlightValue}>{seriesADetails.equityPercentage}</span>
          </div>
        </div>
      </div>

      <div className={styles.marketsSection}>
        <h4 className={styles.sectionTitle}>
          <FiTarget className={styles.sectionIcon} />
          {t('investor_pitch.investment_opportunity.target_markets', 'Initial Target Markets')}
        </h4>
        <ul className={styles.marketsList}>
          {seriesADetails.targetMarkets.map((market, index) => (
            <li key={index} className={styles.marketItem}>{market}</li>
          ))}
        </ul>
      </div>

      <div className={styles.allocationSection}>
        <h4 className={styles.sectionTitle}>
          <FiPieChart className={styles.sectionIcon} />
          {t('investor_pitch.investment_opportunity.allocation_title', 'Allocation of Series A Funds')}
        </h4>
        <div className={styles.allocationChart}>
          {seriesADetails.allocation.map((item, index) => (
            <div key={index} className={styles.allocationItem}>
              <div className={styles.allocationBar}>
                <div 
                  className={styles.allocationFill} 
                  style={{ width: item.percentage }}
                >
                  <span className={styles.allocationPercentage}>{item.percentage}</span>
                </div>
              </div>
              <div className={styles.allocationDetails}>
                <div className={styles.allocationHeader}>
                  <span className={styles.allocationCategory}>{item.category}</span>
                  <span className={styles.allocationAmount}>{item.amount}</span>
                </div>
                <p className={styles.allocationDescription}>{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const renderStructureTab = () => (
    <div className={styles.tabContent}>
      <div className={styles.businessModelSection}>
        <h4 className={styles.sectionTitle}>
          <FiLayers className={styles.sectionIcon} />
          {t('investor_pitch.investment_opportunity.business_model_title', 'Business Model Structure')}
        </h4>
        <div className={styles.businessModelCard}>
          <h5 className={styles.businessModelSubtitle}>
            {t('investor_pitch.investment_opportunity.tech_insurance_relationship', 'Technology-Insurance Relationship')}
          </h5>
          <div className={styles.businessModelDetails}>
            <div className={styles.businessModelItem}>
              <span className={styles.businessModelLabel}>
                {t('investor_pitch.investment_opportunity.tech_royalty', 'Technology Royalty')}
              </span>
              <span className={styles.businessModelValue}>{businessModel.technologyRoyalty}</span>
            </div>
            <div className={styles.businessModelItem}>
              <span className={styles.businessModelLabel}>
                {t('investor_pitch.investment_opportunity.operational_expense', 'Operational Expense')}
              </span>
              <span className={styles.businessModelValue}>{businessModel.operationalExpense}</span>
            </div>
            <div className={styles.businessModelItem}>
              <span className={styles.businessModelLabel}>
                {t('investor_pitch.investment_opportunity.total_expense', 'Total Expense Ratio')}
              </span>
              <span className={styles.businessModelValue}>{businessModel.totalExpenseRatio}</span>
            </div>
            <div className={styles.businessModelItem}>
              <span className={styles.businessModelLabel}>
                {t('investor_pitch.investment_opportunity.industry_average', 'Industry Average')}
              </span>
              <span className={styles.businessModelValue}>{businessModel.industryAverage}</span>
            </div>
          </div>
          <div className={styles.businessModelAdvantage}>
            <div className={styles.advantageBar}>
              <div className={styles.allegoryBar} style={{ width: '15%' }}>
                <span>Allegory 8%</span>
              </div>
              <div className={styles.industryBar} style={{ width: '85%' }}>
                <span>Industry 25-40%</span>
              </div>
            </div>
            <p className={styles.advantageText}>
              {t('investor_pitch.investment_opportunity.expense_advantage', 'Allegory\'s AI-driven operations enable an 85% reduction in expense ratio compared to traditional insurers')}
            </p>
          </div>
        </div>
      </div>

      <div className={styles.parallelCapitalSection}>
        <h4 className={styles.sectionTitle}>
          <FiDollarSign className={styles.sectionIcon} />
          {t('investor_pitch.investment_opportunity.parallel_capital_title', 'Parallel Capital Raises')}
        </h4>
        <div className={styles.parallelCapitalGrid}>
          {parallelCapital.map((item, index) => (
            <div key={index} className={styles.parallelCapitalCard}>
              <div className={styles.parallelCapitalHeader}>
                <h5 className={styles.parallelCapitalCategory}>{item.category}</h5>
                <span className={styles.parallelCapitalAmount}>{item.amount}</span>
              </div>
              <p className={styles.parallelCapitalDescription}>{item.description}</p>
            </div>
          ))}
        </div>
        <div className={styles.capitalStructureNote}>
          <p>
            {t('investor_pitch.investment_opportunity.capital_note', 'Note: These parallel capital raises are structured separately from the Series A investment in Allegory Technology Inc. and will be deployed strategically to maximize return on the core technology platform.')}
          </p>
        </div>
      </div>
    </div>
  );

  const renderTimelineTab = () => (
    <div className={styles.tabContent}>
      <div className={styles.timelineSection}>
        <h4 className={styles.sectionTitle}>
          <FiCalendar className={styles.sectionIcon} />
          {t('investor_pitch.investment_opportunity.timeline_title', 'Strategic Timeline & Milestones')}
        </h4>
        <div className={styles.timelineContainer}>
          {timeline.map((period, index) => (
            <div key={index} className={styles.timelinePeriod}>
              <div className={styles.timelineHeader}>
                <div className={styles.timelineIndicator} />
                <h5 className={styles.timelinePhase}>{period.phase}</h5>
              </div>
              <ul className={styles.timelineMilestones}>
                {period.milestones.map((milestone, mIndex) => (
                  <li key={mIndex} className={styles.timelineMilestone}>{milestone}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.criticalTimingSection}>
        <h4 className={styles.sectionTitle}>
          {t('investor_pitch.investment_opportunity.critical_timing_title', 'Critical Timing Considerations')}
        </h4>
        <div className={styles.criticalTimingCard}>
          <ul className={styles.criticalTimingList}>
            <li>
              {t('investor_pitch.investment_opportunity.timing_point_1', 'Our regulatory strategy requires capital deployment by Q3 2025 to capture optimal market conditions')}
            </li>
            <li>
              {t('investor_pitch.investment_opportunity.timing_point_2', 'Currently prioritizing strategic VC partnerships, but operating on a defined timeline for investment decisions')}
            </li>
            <li>
              {t('investor_pitch.investment_opportunity.timing_point_3', 'Several regulatory windows for dormant license acquisition open in Q3-Q4 2025, creating strategic urgency')}
            </li>
            <li>
              {t('investor_pitch.investment_opportunity.timing_point_4', 'Active discussions underway with multiple capital sources interested in the insurance AI space')}
            </li>
            <li>
              {t('investor_pitch.investment_opportunity.timing_point_5', 'Early partnership discussions with distribution channels indicate strong demand for our AI-driven underwriting')}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case 'strategy':
        return renderStrategyTab();
      case 'structure':
        return renderStructureTab();
      // case 'timeline':
      //   return renderTimelineTab();
      default:
        return renderStrategyTab();
    }
  };

  return (
    <motion.div
      className={styles.container}
      variants={ANIMATION_VARIANTS.stagger.container}
      initial="hidden"
      animate="visible"
    >
      {/* Main Header */}
      <motion.div
        className={styles.header}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <h2 className={styles.mainTitle}>
          {t(
            'investor_pitch.investment_opportunity.title',
            'Strategic Investment Opportunity'
          )}
        </h2>
        <p className={styles.subtitle}>
          {t(
            'investor_pitch.investment_opportunity.subtitle',
            'A structured approach to building the world\'s first trillion-dollar insurance company'
          )}
        </p>
      </motion.div>

      <motion.div
        className={styles.content}
        variants={ANIMATION_VARIANTS.fadeIn}
      >
        <div className={styles.tabsContainer}>
          <div className={styles.tabs}>
            {tabs.map(tab => (
              <button
                key={tab.id}
                className={`${styles.tabButton} ${activeTab === tab.id ? styles.activeTab : ''}`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.label}
              </button>
            ))}
          </div>
          
          <div className={styles.tabContentContainer}>
            {renderTabContent()}
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default InvestmentOpportunitySlide;