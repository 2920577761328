// File: frontend/src/pages/InvestorPitch/CustomerBrokers.js

import React from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useParams } from 'react-router-dom';
import PitchDeck from './PitchDeck';
import { getCustomerBrokersSlides } from './configs/CustomerBrokersConfig';

const CustomerBrokers = () => {
  const { t } = useLanguage();
  const slides = getCustomerBrokersSlides(t);
  const { id } = useParams();  // This will now get the id from the URL path

  const enterpriseHash = id;
  console.log('Extracted enterprise hash:', enterpriseHash);

  return <PitchDeck 
    slides={slides} 
    deckId="customer-brokers"  // Explicitly set the deckId
    enterpriseHash={enterpriseHash} 
    requireAuth={true} 
  />;
};

export default CustomerBrokers;